export const balanceSheetRenderText = {
    "en-US": {
        "Options": "Options",
        "As of:": "As of:",
        "Date range": "Date range",
        "Compare": "Compare",
        "Custom": "Custom",
        "Update report": "Update report",
        "Invalid date(s) selected.": "Invalid date(s) selected.",
        "yyyy-mm-dd": "yyyy-mm-dd",

        "Assets": "Assets",
        "Current assets": "Current assets",
        "Total current assets": "Total current assets",
        "Non-current assets": "Non-current assets",
        "Total non-current assets": "Total non-current assets",
        "Total assets": "Total assets",

        "Liabilities": "Liabilities",
        "Current liabilities": "Current liabilities",
        "Total current liabilities": "Total current liabilities",
        "Non-current liabilities": "Non-current liabilities",
        "Total non-current liabilities": "Total non-current liabilities",
        "Total liabilities": "Total liabilities",

        "Equity": "Equity",
        "Retained Earnings": "Retained Earnings",
        "Retained earnings": "Retained earnings",
        "Beginning balances": "Beginning balances",
        "Net income for current fiscal period": "Net income for current fiscal period",  
        "Dividends for current fiscal period": "Less dividends and equivalents for current fiscal period",
        "Ending balances of retained earnings": "Ending balances of retained earnings",
        "Total equity": "Total equity",

        "This balance sheet does not satisfy the accounting equation. Please check that the initial debit and credit values of all accounts are set up correctly.": "This balance sheet does not satisfy the accounting equation. Please check that the initial debit and credit values of all accounts are set up correctly, and that initial values of retained earnings are set up correctly.",

        //Account Subtype Names.
        "Cash and cash equivalents": "Cash and cash equivalents", 
        "Current marketable securities": "Current marketable securities", 
        "Current receivables": "Current receivables", 
        "Inventory": "Inventory", 
        "Other current assets": "Other current assets", 
        "Non-current marketable securities": "Non-current marketable securities", 
        "Non-current receivables": "Non-current receivables",
        "Property, plant, and equipment": "Property, plant, and equipment", 
        "Intangible assets and goodwill": "Intangible assets and goodwill",
        "Other non-current assets": "Other non-current assets", 
        "Current payables": "Current payables", 
        "Dividends and equivalents payable": "Dividends and equivalents payable",
        "Deferred revenue": "Deferred revenue", 
        "Short-term debt": "Short-term debt", 
        "Deferred tax": "Deferred tax", 
        "Other current liabilities": "Other current liabilities", 
        "Long-term debt": "Long-term debt", 
        "Non-current payables": "Non-current payables", 
        "Other non-current liabilities": "Other non-current liabilities", 
        "Paid-in capital": "Paid-in capital", 
        "Share-based compensation": "Share-based compensation",
        "Dividends and equivalents": "Dividends and equivalents", 
        "Other equity items": "Other equity items", 
        "Revenue": "Revenue", 
        "Income from investing activities": "Income from investing activities",
        "Income from financing activities": "Income from financing activities",
        "Other income": "Other income", 
        "Cost of sales": "Cost of sales", 
        "Research and development": "Research and development", 
        "Selling, general, and administration": "Selling, general, and administration", 
        "Depreciation and amortization": "Depreciation and amortization", 
        "Other expense": "Other expense", 
        "Interest expense": "Interest expense",
        "Tax expense": "Tax expense",
        "Non-recurring and extraordinary items": "Non-recurring and extraordinary items",
        "Interest income": "Interest income",
        "Interest payable": "Interest payable",
        "Taxes payable": "Taxes payable",
        "Expense from investing activities": "Expense from investing activities",
        "Expense from financing activities": "Expense from financing activities",

        "Detailed View": "Detailed View"
    },
    "zh-TW": {
        "Options": "調整選項",
        "As of:": "截至",
        "Date range": "日期區間",
        "Compare": "比較對照",
        "Custom": "自訂日期",
        "Update report": "更新報表",
        "Invalid date(s) selected.": "日期有誤。",
        "yyyy-mm-dd": "yyyy-mm-dd", //date string format

        "Assets": "資產",
        "Current assets": "流動資產",
        "Total current assets": "流動資產合計",
        "Non-current assets": "非流動資產",
        "Total non-current assets": "非流動資產合計",
        "Total assets": "資產總計",

        "Liabilities": "負債",
        "Current liabilities": "流動負債",
        "Total current liabilities": "流動負債合計",
        "Non-current liabilities": "非流動流動負債",
        "Total non-current liabilities": "非流動負債合計",
        "Total liabilities": "負債總計",

        "Equity": "權益",
        "Retained Earnings": "保留盈餘",
        "Retained earnings": "保留盈餘",
        "Beginning balances": "初始餘額",
        "Net income for current fiscal period": "本期淨收入",  
        "Dividends for current fiscal period": "本期股利",
        "Ending balances of retained earnings": "期末保留營餘",
        "Total equity": "權益總計",

        "This balance sheet does not satisfy the accounting equation. Please check that the initial debit and credit values of all accounts are set up correctly.": "本資產負債表不符合會計衡等式，請檢查各會計科目的初始值和保留盈餘的初始值没有錯誤（必需借貸平衡）且無遺漏。",

        //Account Subtype Names.
        "Cash and cash equivalents": "現金及約當現金", 
        "Current marketable securities": "流動市場性證劵", 
        "Current receivables": "流動應收帳款", 
        "Inventory": "存貨", 
        "Other current assets": "其他流動資產", 
        "Non-current marketable securities": "非流動市場性證劵", 
        "Non-current receivables": "非流動應收帳款",
        "Property, plant, and equipment": "不動產、廠房及設備", 
        "Intangible assets and goodwill": "無形資產及商譽",
        "Other non-current assets": "其他非流動資產", 
        "Current payables": "流動應付帳款", 
        "Dividends and equivalents payable": "應付股利",
        "Deferred revenue": "遞延收入", 
        "Short-term debt": "短期債務借款", 
        "Deferred tax": "遞延所得稅", 
        "Other current liabilities": "其他流動負債", 
        "Long-term debt": "長期債務借款", 
        "Non-current payables": "非流動應付帳款", 
        "Other non-current liabilities": "其他非流動負債", 
        "Paid-in capital": "投入資本", 
        "Share-based compensation": "權益奬酬",
        "Dividends and equivalents": "本期股利及約當股利", 
        "Other equity items": "其他業主權益", 
        "Revenue": "收入", 
        "Income from investing activities": "投資活動之收入",
        "Income from financing activities": "籌資活動之收入",
        "Other income": "其他收入", 
        "Cost of sales": "營業成本", 
        "Research and development": "研究發展費用", 
        "Selling, general, and administration": "銷售、總務及管理費用", 
        "Depreciation and amortization": "折舊及攤銷", 
        "Other expense": "其他費用", 
        "Interest expense": "利息費用",
        "Tax expense": "賦稅費用",
        "Non-recurring and extraordinary items": "非經常性項目",
        "Interest income": "利息收入",
        "Interest payable": "應付利息",
        "Taxes payable": "應付稅款",
        "Expense from investing activities": "投資活動之費用",
        "Expense from financing activities": "籌資活動之費用",

        "Detailed View": "細項檢視"
    }

}