export const accountDetailsEditorText = {
    "en-US": {
        "Account Details": "Account Details",
        "Parent Account": "Parent Account",
        "Account Type": "Account Type:",
        "Account Subtype": "Account Subtype",
        "Total Debit Amount:": "Total Debit Amount:",
        "Total Credit Amount:": "Total Credit Amount:",
        "Edit Account Details": "Edit Account Details",
        "Account Name": "Account Name",
        "Please provide a name for your account.": "Please provide a name for your account.",
        "Account Group": "Account Group",
        "Save": "Save",
        "Cancel": "Cancel",
        "Delete": "Delete",
        "Yes, delete it!": "Yes, delete it!",
        "Are you sure?": "Are you sure?",
        "Cannot delete this account.": "Cannot delete this account.",
        "Are you sure you want to delete this account?": "Are you sure you want to delete this account?",
        "Please remove all line items and child accounts from this account and try again.": "Please remove all line items and child accounts from this account and try again.",
        "Initial Debit Value": "Initial Debit Value",
        "Initial Credit Value": "Initial Credit Value",
        "Create a New Account": "Create a New Account",
        "Account must belong to either a subtype or a parent account.": "Account must belong to either a subtype or a parent account.",
        "Account Code": "Account Code",
        "Create a New Category": "Create a New Category",
        "Edit Category Details": "Edit Category Details",
        "Please provide a name for your category.": "Please provide a name for your category.",
        "Category Name": "Category Name",
        "Parent Category": "Parent Category",
        "Are you sure you want to delete this category?": "Are you sure you want to delete this category?",
        "Cannot delete this category.": "Cannot delete this category.",
        "Please remove all line items and child categories from this category and try again." : "Please remove all line items and child categories from this category and try again.",
        "Initial Account Value": "Initial Account Value",
        "Display Order": "Display Order",

        "This action requires EDIT permissions for this ledger.": "This action requires EDIT permissions for this ledger.",

        //Account Subtype Names.
        "Cash and cash equivalents": "Cash and cash equivalents", 
        "Current marketable securities": "Current marketable securities", 
        "Current receivables": "Current receivables", 
        "Inventory": "Inventory", 
        "Other current assets": "Other current assets", 
        "Non-current marketable securities": "Non-current marketable securities", 
        "Non-current receivables": "Non-current receivables",
        "Property, plant, and equipment": "Property, plant, and equipment", 
        "Intangible assets and goodwill": "Intangible assets and goodwill",
        "Other non-current assets": "Other non-current assets", 
        "Current payables": "Current payables", 
        "Dividends and equivalents payable": "Dividends and equivalents payable",
        "Deferred revenue": "Deferred revenue", 
        "Short-term debt": "Short-term debt", 
        "Deferred tax": "Deferred tax", 
        "Other current liabilities": "Other current liabilities", 
        "Long-term debt": "Long-term debt", 
        "Non-current payables": "Non-current payables", 
        "Other non-current liabilities": "Other non-current liabilities", 
        "Paid-in capital": "Paid-in capital", 
        "Share-based compensation": "Share-based compensation",
        "Dividends and equivalents": "Dividends and equivalents", 
        "Other equity items": "Other equity items", 
        "Revenue": "Revenue", 
        "Income from investing activities": "Income from investing activities",
        "Income from financing activities": "Income from financing activities",
        "Other income": "Other income", 
        "Cost of sales": "Cost of sales", 
        "Research and development": "Research and development", 
        "Selling, general, and administration": "Selling, general, and administration", 
        "Depreciation and amortization": "Depreciation and amortization", 
        "Other expense": "Other expense", 
        "Expense from investing activities": "Expense from investing activities",
        "Expense from financing activities": "Expense from financing activities",
        "Interest expense": "Interest expense",
        "Tax expense": "Tax expense",
        "Non-recurring and extraordinary items": "Non-recurring and extraordinary items",
        "Interest income": "Interest income",
        "Interest payable": "Interest payable",
        "Taxes payable": "Taxes payable"
    },
    "zh-TW": {
        "Account Details": "科目說明",
        "Parent Account": "母科目:",
        "Account Type:": "主類別:",
        "Account Subtype": "科目類型",
        "Total Debit Amount:": "借方加總:",
        "Total Credit Amount:": "貸方加總:",
        "Edit Account Details": "修改科目",
        "Account Name": "科目名稱",
        "Please provide a name for your account.": "請填寫會計科目名稱。",
        "Account Group": "科目群",
        "Save": "儲存",
        "Cancel": "取消",
        "Delete": "删除",
        "Yes, delete it!": "是的，删除它！",
        "Are you sure?": "你真的確定嗎？",
        "Cannot delete this account.": "無法删除本科目。",
        "Are you sure you want to delete this account?": "你真的確定要删除這個科目嗎？",
        "Please remove all line items and child accounts from this account and try again.": "請删先本科目下所有單行項目及其子科目後再試一次。",
        "Initial Debit Value": "借方初始餘額",
        "Initial Credit Value": "貸方初始餘額",
        "Create a New Account": "建立一個新科目",
        "Account must belong to either a subtype or a parent account.": "新建子科目必需隸屬在一個已存在的「母科目」下；新建母科目則必需有它的「科目類型」。",
        "Account Code": "科目代碼",
        "Create a New Category": "創立一個新類別",
        "Edit Category Details": "修改類別",
        "Please provide a name for your category.": "請填寫類別名稱。",
        "Category Name": "類別名稱",
        "Parent Category": "母類別",
        "Are you sure you want to delete this category?": "你確定你要删除這個類別嗎？",
        "Cannot delete this category.": "無法删除本類別。",
        "Please remove all line items and child categories from this category and try again." : "請删先本類別下所有單行項目及其子類別後再試一次。",
        "Initial Account Value": "科目初始餘額",

        "This action requires EDIT permissions for this ledger.": "使用本功能需有編修者權限。",
        
        //Account Subtype Names.
        "Cash and cash equivalents": "現金及約當現金", 
        "Current marketable securities": "流動市場性證劵", 
        "Current receivables": "流動應收帳款", 
        "Inventory": "存貨", 
        "Other current assets": "其他流動資產", 
        "Non-current marketable securities": "非流動市場性證劵", 
        "Non-current receivables": "非流動應收帳款",
        "Property, plant, and equipment": "不動產、廠房及設備", 
        "Intangible assets and goodwill": "無形資產及商譽",
        "Other non-current assets": "其他非流動資產", 
        "Current payables": "流動應付帳款", 
        "Dividends and equivalents payable": "應付股利",
        "Deferred revenue": "遞延收入", 
        "Short-term debt": "短期債務借款", 
        "Deferred tax": "遞延所得稅", 
        "Other current liabilities": "其他流動負債", 
        "Long-term debt": "長期債務借款", 
        "Non-current payables": "非流動應付帳款", 
        "Other non-current liabilities": "其他非流動負債", 
        "Paid-in capital": "投入資本", 
        "Share-based compensation": "權益奬酬",
        "Dividends and equivalents": "本期股利及約當股利", 
        "Other equity items": "其他業主權益", 
        "Revenue": "收入", 
        "Income from investing activities": "投資活動之收入",
        "Income from financing activities": "籌資活動之收入",
        "Other income": "其他收入", 
        "Cost of sales": "營業成本", 
        "Research and development": "研究發展費用", 
        "Selling, general, and administration": "銷售、總務及管理費用", 
        "Depreciation and amortization": "折舊及攤銷", 
        "Expense from investing activities": "投資活動之費用",
        "Expense from financing activities": "籌資活動之費用",
        "Other expense": "其他費用", 
        "Interest expense": "利息費用",
        "Tax expense": "稅務費用",
        "Non-recurring and extraordinary items": "非經常性項目",
        "Interest income": "利息收入",
        "Interest payable": "應付利息",
        "Taxes payable": "應付稅款",
        "Display Order": "顯示順序"
    }
}