export const cashFlowReportText = {
    "en-US": {
        "Home": "Home",
        "Reports": "Reports",
        "Cash Flow Report": "Cash Flow Report",
        "Cash Flow Statement": "Cash Flow Statement",
        "Options": "Options",
        "Update report": "Update report",
        "Date range": "Date range",
        "Compare": "Compare",
        "Invalid date(s) selected.": "Invalid date(s) selected.",
        "yyyy-mm-dd": "yyyy-mm-dd", //date string format

        "From:": "From:",
        "To:" : "To:",
        "Cash and cash equivalents, beginning of period": "Cash and cash equivalents, beginning of period",
        "Operating activities": "Operating activities",
        "Net Income": "Net income",
        "Adjustments to reconcile operating income to cash generated by operating activities": "Adjustments to reconcile operating income to cash generated by operating activities",
        "Depreciation and amortization": "Depreciation and amortization",
        "Increase (decrease) in deferred taxes": "Increase (decrease) in deferred taxes",
        "Share-based compensation": "Share-based compensation",
        "Adjustment for non-operating loss (income)": "Adjustment for non-operating loss (income)",
        "Changes in operating assets and liabilities": "Changes in operating assets and liabilities",
        "Decrease (increase) in receivables": "Decrease (increase) in receivables",
        "Increase (decrease) in payables": "Increase (decrease) in payables",
        "Decrease (increase) in inventory": "Decrease (increase) in inventory",
        "Increase (decrease) in deferred revenue": "Increase (decrease) in deferred revenue",
        "Decrease (increase) in other assets": "Decrease (increase) in other assets",
        "Increase (decrease) in other liabilities": "Increase (decrease) in other liabilities",
        "Cash flow from operations": "Cash flow from operations",
        "Investing activities": "Investing activities",
        "Income (loss) from investing, net": "Income (loss) from investing, net",
        "Decrease (increase) in marketable securities": "Decrease (increase) in marketable securities",
        "Decrease (increase) in property, plant, and equipment": "Decrease (increase) in property, plant, and equipment",
        "Cash flow from investing": "Cash flow from investing",
        "Financing activities": "Financing activities",
        "Income (loss) from financing activities, net": "Income (loss) from financing activities, net",
        "Increase (decrease) in paid-in capital": "Increase (decrease) in paid-in capital",
        "Increase (decrease) in other equity": "Increase (decrease) in other equity",
        "Payments for dividends and equivalents": "Payments for dividends and equivalents",
        "Increase (decrease) in debt": "Increase (decrease) in debt",
        "Cash flow from financing": "Cash flow from financing",
        "Increase (decrease) in cash and equivalents": "Increase (decrease) in cash and equivalents",
        "Cash and cash equivalents, end of period": "Cash and cash equivalents, end of period",
        "Adjustment for change in book value of property, plant, and equipment due to depreciation and amortization": "Adjustment for change in book value due to depreciation and amortization",
    
        "Adjustment for non-operating income and expenses": "Adjustment for non-operating income and expenses",
        "Income and expenses from operations, net": "Income and expenses from operations, net",
        "Adjustment for non-cash operating income and expenses" : "Adjustment for non-cash operating income and expenses",
        "Changes in operating equity": "Changes in operating equity",
        "Income and expenses from investing, net": "Income and expenses from investing, net",
        "Adjustments to reconcile investing income to cash generated by investing activities": "Adjustments to reconcile investing income to cash generated by investing activities",
        "Adjustment for non-cash investing income and expenses": "Adjustment for non-cash investing income and expenses",
        "Changes in investing assets and liabilities": "Changes in investing assets and liabilities",
        "Adjustment for change in book value due to depreciation and amortization": "Adjustment for change in book value due to depreciation and amortization",
        "Changes in investing equity": "Changes in investing equity",
        "Income and expenses from financing, net": "Income and expenses from financing, net",
        "Adjustments to reconcile financing income to cash generated by financing activities": "Adjustments to reconcile financing income to cash generated by financing activities",
        "Adjustment for non-cash investing income and expenses": "Adjustment for non-cash investing income and expenses",
        "Changes in non-dividend assets and liabilities": "Changes in non-dividend assets and liabilities",
        "Changes in financing equity": "Changes in financing equity",
        "Dividend equity": "Dividend equity",
        "Dividend liabilities": "Dividend liabilities",
        "Interest paid": "Interest paid",
        "Interest expense": "Interest expense",
        "Interest liabilities": "Interest liabilities",
        "Taxes paid": "Taxes paid",
        "Tax expense": "Tax expense",
        "Tax liabilities": "Tax liabilities",

    },
    "zh-TW": {
        "Home": "首頁",
        "Reports": "報表",
        "Cash Flow Report": "現金流量表",
        "Cash Flow Statement": "現金流量表",
        "Options": "設定選項",
        "Update report": "更新報表",
        "Date range": "日期區間",
        "Compare": "比較對照",
        "Invalid date(s) selected.": "日期有誤。",
        "yyyy-mm-dd": "yyyy-mm-dd", //date string format


        "From:": "自:",
        "To:" : "至:",
        "Cash and cash equivalents, beginning of period": "期初 現金及約當現金",
        "Operating activities": "營業活動",
        "Net Income": "淨收入",
        "Adjustments to reconcile operating income to cash generated by operating activities": "調整項目",
        "Depreciation and amortization": "折舊及攤提",
        "Increase (decrease) in deferred taxes": "遞延賦稅增加（減少）",
        "Share-based compensation": "權益奬酬",
        "Adjustment for non-operating loss (income)": "營業外活動損失（收益）調整",
        "Changes in operating assets and liabilities": "與營業相關之資產／負債變動數",
        "Decrease (increase) in receivables": "應收帳款減少（增加）",
        "Increase (decrease) in payables": "應付帳款增加（減少）",
        "Decrease (increase) in inventory": "存貨減少（增加）",
        "Increase (decrease) in deferred revenue": "遞延收入增加（減少）",
        "Decrease (increase) in other assets": "其他資產減少（增加）",
        "Increase (decrease) in other liabilities": "其他負債增加",
        "Cash flow from operations": "營業活動之現金流量",
        "Investing activities": "投資活動",
        "Income (loss) from investing, net": "投資活動之淨收入（損失）",
        "Decrease (increase) in marketable securities": "市場性證劵減少（增加）",
        "Decrease (increase) in property, plant, and equipment": "不動產、廠房及設備減少（增加）",
        "Cash flow from investing": "投資活動之現金流量",
        "Financing activities": "籌資活動",
        "Income (loss) from financing activities, net": "籌資活動之收入（損失）",
        "Increase (decrease) in paid-in capital": "投入資本增加（減少）",
        "Increase (decrease) in other equity": "其他權益增加（減少）",
        "Payments for dividends and equivalents": "支付股利及股息等值",
        "Increase (decrease) in debt": "債務增加（減少）",
        "Cash flow from financing": "籌資活動之現金流量",
        "Increase (decrease) in cash and equivalents": "現金及約當現金增加（減少）",
        "Cash and cash equivalents, end of period": "期末 現金及約當現金",
        "Adjustment for change in book value of property, plant, and equipment due to depreciation and amortization": "不動產、廠房及設備帳面價值之調整",

        "Adjustment for non-operating income and expenses": "營業外收入和費用之調整",
        "Income and expenses from operations, net": "營業活動收入和費用淨額",
        "Adjustment for non-cash operating income and expenses" : "非現金營業活動收入和費用之調整",
        "Changes in operating equity": "經營權益變動",
        "Income and expenses from investing, net": "投資活動收入和費用淨額",
        "Adjustments to reconcile investing income to cash generated by investing activities": "為投資收入與投資活動所產生的現金相符之調整",
        "Adjustment for non-cash investing income and expenses": "非現金投資活動收入和費用之調整",
        "Changes in investing assets and liabilities": "投資資產和負債的變動",
        "Adjustment for change in book value due to depreciation and amortization": "不動產、廠房及設備帳面價值之調整",
        "Changes in investing equity": "投資權益變動",
        "Income and expenses from financing, net": "籌資活動收入和費用淨額",
        "Adjustments to reconcile financing income to cash generated by financing activities": "為籌資收入與籌資活動產生的現金相符之調整",
        "Adjustment for non-cash investing income and expenses": "非現金投資收入和費用之調整",
        "Changes in non-dividend assets and liabilities": "非股息資產和負債的變動",
        "Changes in financing equity": "籌資權益變動",
        "Dividend equity": "股息權益",
        "Dividend liabilities": "股息負債",
        "Interest paid": "支付利息",
        "Interest expense": "利息費用",
        "Interest liabilities": "利息負債",
        "Taxes paid": "支付稅款",
        "Tax expense": "稅務費用",
        "Tax liabilities": "稅務負債",
    }
}