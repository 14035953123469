import React from 'react';
import {Link} from 'react-router-dom';

const today = new Date();

export const registerV3Text = {
    "en-US": {
        "App description": "Bookkeeping made easy.",
        "Sign Up": "Sign Up",
        "Create your myEasyLedger Account.": "Create your myEasyLedger Account.",
        "Name": "Name",
        "First name": "First name",
        "Last name": "Last name",
        "Email": "Email",
        "Email address": "Email address",
        "Email is already taken.": "Email is already taken.",
        "Re-enter Email": "Re-enter Email",
        "Re-enter email address": "Re-enter email address",
        "Email does not match.": "Email does not match.",
        "Ledger Name": "Ledger Name",
        "Password": "Password",
        "Re-enter Password": "Re-enter Password",
        "Password does not match.": "Password does not match.",
        "Agreement text": <div>By clicking Sign Up, you agree to our <Link to="#" className="text-primary">Terms</Link> and that you have read our <Link to="#" className="text-primary">Data Policy</Link>, including our <Link to="#" className="text-primary">Cookie Use</Link>.</div>,
        "Please agree.": "Please agree.",
        "Already a member": <div>Already a member? Click <Link to="/user/login/form" className="text-primary">here</Link> to login.</div>,
        "Copyright text": `\u00A9 ${today.getFullYear()} myEasyLedger.com All Rights Reserved.`,

        "Next": "Next",
        "Create your first ledger.": "Create your first ledger.",
        "Something went wrong. Please try again later.": "Something went wrong. Please try again later.",
        "Currency": "Currency",
        "Create a ledger using:": "Create a ledger using:",
        "Double-entry accounting": "Double-entry accounting",
        "Single-entry accounting": "Single-entry accounting",
        "Registration Successful!": "Registration Successful!",
        "A verification email is on its way to your inbox.": "A verification email is on its way to your inbox.",
        "Return to login page.": "Return to login page.",
        "Password must be 8-32 characters long.": "Password must be 8-32 characters long.",

        "Finish setting up your account.": "Finish setting up your account.",
        "Finish": "Finish",
        "You've finished setting up your account!": "You've finished setting up your account!",
        "This account has already been set up.": "This account has already been set up.",
        "This invitation link is invalid.": "This invitation link is invalid.",
        "Please click here to return to the login page.": <>Please click <Link to="/user/login/form" className="text-primary">here</Link> to return to the login page.</>
    },
    "zh-TW": {
        "App description": "易記帳。",
        "Sign Up": "建立新使用帳號",
        "Create your myEasyLedger Account.": "建立一個新的使用帳號。",
        "Name": "您的名字",
        "First name": "姓",
        "Last name": "名",
        "Email": "您的電子郵箱",
        "Email address": "電郵址",
        "Email is already taken.": "該電郵已有 myEasyLedger 的帳號在使用中。",
        "Re-enter Email": "再次輸入電郵址",
        "Re-enter email address": "再一次輸入你的電郵址",
        "Email does not match.": "輸入的電郵址前後不符。",
        "Ledger Name": "帳本名稱",
        "Password": "密碼",
        "Re-enter Password": "再一次輸入你的密碼",
        "Password does not match.": "輸入的密碼前後不符。",
        "Agreement text": <div>點擊要建立新帳號，即表示您同意了我們的 <Link to="#" className="text-primary">使用條款</Link> 也讀了我們的 <Link to="#" className="text-primary">資料政策</Link>，包抱 <Link to="#" className="text-primary">Cookie 的使用</Link>。</div>,
        "Please agree.": "請同意。",
        "Already a member": <div>你已經有帳號了嗎？點擊 <Link to="/user/login/form" className="text-primary">這裡</Link> 就可以登入。</div>,
        "Copyright text": `\u00A9 ${today.getFullYear()} myEasyLedger.com 版權所有。`,

        "Next": "下一步",
        "Create your first ledger.": "創建立你的第一本帳本。",
        "Something went wrong. Please try again later.": "好像出了點問題，請稍後再試。",
        "Currency": "幣別",
        "Create a ledger using:": "新帳本的簿記方式：",
        "Double-entry accounting": "複式簿記（公司行號版）",
        "Single-entry accounting": "單式簿記（個人／家庭版）",
        "Registration Successful!": "註册成功！",
        "A verification email is on its way to your inbox.": "一封確認電郵正在寄到你電郵箱的路上。",
        "Return to login page.": "回到登入頁。",
        "Password must be 8-32 characters long.": "密碼必需介於 8-32 字元長度。",

        "Finish setting up your account.": "完成設定你的帳號。",
        "Finish": "完成",
        "You've finished setting up your account!": "你已完成設定你的帳號！",
        "This account has already been set up.": "這個帳號已經成功開通。",
        "This invitation link is invalid.": "這個邀請連結已過期或無效。",
        "Please click here to return to the login page.": <>請點擊 <Link to="/user/login/form" className="text-primary">這裡</Link> 回到登入頁。</>

    }
}