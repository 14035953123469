export const chartOfAccountsText = {
    "en-US": {
        //Account Type Names.
        "Assets": "Assets",
        "Liabilities": "Liabilities",
        "Owner's Equity": "Equity",
        "Income": "Income",
        "Expenses": "Expenses",

        "Home": "Home",
        "Chart of Accounts": "Chart of Accounts",
        "Create an account": "Create an account",
        "Create a New Account": "Create a New Account",
        "Please provide a name for your account.": "Please provide a name for your account.",
        "Please provide an account subtype for your account.": "Please provide an account subtype for your account.",
        "Account Group Name": "Account Group Name",
        "Account Type": "Account Type",
        "Account Subtype": "Account Subtype",
        "Save": "Save",
        "Cancel": "Cancel",
        "Are you sure you want to delete this account group?": "Are you sure you want to delete this account group?",
        "Yes, delete it!": "Yes, delete it!",
        "Are you sure?": "Are you sure?",
        "Cannot delete this account group. Please delete all accounts in this account group and try again.": "Cannot delete this account group. Please delete all accounts in this account group and try again.",
        "Cannot delete this account group.": "Cannot delete this account group.",
        "Add an Account": "Add an Account",
        "Account Name": "Account Name",
        "Accounts": "Accounts",
        "Categories": "Categories",
        "Create a category": "Create a category",
        "Create a New Category Group": "Create a New Category Group",
        "Please provide a name for your category group.": "Please provide a name for your category group.",
        "Category Group Name": "Category Group Name",
        "Category Type": "Category Type",
        "Edit Category Group Details": "Edit Category Group Details",
        "Edit Account Details": "Edit Account Details",
        "Are you sure you want to delete this category group?": "Are you sure you want to delete this category group?",
        "Cannot delete this category group. Please delete all categories in this category group and try again.": "Cannot delete this category group. Please delete all categories in this category group and try again.",
        "Cannot delete this category.": "Cannot delete this category.",
        "Add a Category": "Add a Category",
        "Please provide a name for your category.": "Please provide a name for your category.",
        "Category Name": "Category Name",

        "Initial Debit Value": "Initial Debit Value",
        "Initial Credit Value": "Initial Credit Value",
        "Add a new child account...": "Add a new child account...",
        "Add a new child category...": "Add a new child category...",
        "This action requires EDIT permissions for this ledger.": "This action requires EDIT permissions for this ledger.",


    }, 
    "zh-TW": {
        //Account Type Names.
        "Assets": "資產",
        "Liabilities": "負債",
        "Owner's Equity": "權益",
        "Income": "收益",
        "Expenses": "費損",

        "Home": "首頁",
        "Chart of Accounts": "會計科目表",
        "Create an account": "建立新科目",
        "Create a New Account": "建立一個新會計科目",
        "Please provide a name for your account.": "請提供科目名稱。",
        "Please provide an account subtype for your account.": "請提供科目所屬的子類別。",
        "Account Group Name": "科目群名稱",
        "Account Type": "主類別",
        "Account Subtype": "科目類型",
        "Save": "儲存",
        "Cancel": "取消",
        "Are you sure you want to delete this account group?": "你確定要删除這個科目群嗎？",
        "Yes, delete it!": "是的，删除它！",
        "Are you sure?": "你確定嗎？",
        "Cannot delete this account group. Please delete all accounts in this account group and try again.": "無法删除此科目群，請先删除此科目群下所有的科目後再試一次。",
        "Cannot delete this account group.": "無法删除此科目群。",
        "Add an Account": "增設一個新科目",
        "Account Name": "科目名稱",
        "Accounts": "帳戶／錢包",
        "Categories": "收支類別",
        "Create a category": "建立新類別",
        "Create a New Category Group": "建立一個新的類別群",
        "Please provide a name for your category group.": "請給類別群取個名稱",
        "Category Group Name": "科目群名稱",
        "Category Type": "主類別",
        "Edit Category Group Details": "修改類別群",
        "Edit Account Details": "修改科目說明",
        "Are you sure you want to delete this category group?": "你真的確定要删除這個類別群嗎？",
        "Cannot delete this category group. Please delete all categories in this category group and try again.": "無法删除此類別群，請先删除此類別群下所有的類別後再試一次。",
        "Cannot delete this category.": "無法删除此類別。",
        "Add a Category": "增設一個新類別",
        "Please provide a name for your category.": "請給類別取個名稱。",
        "Category Name": "類別名稱",

        "Initial Debit Value": "借方初始餘額",
        "Initial Credit Value": "貸方初始餘額",
        "Add a new child account...": "建立一個新的子科目",
        "Add a new child category...": "建立一個新的子類別",
        "This action requires EDIT permissions for this ledger.": "使用本功需有編修者權限。"


    }
}