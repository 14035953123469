export const incomeStatementRenderText = {
    "en-US": {
        "Options": "Options",
        "From:": "From:",
        "To:": "To:",
        "Date range": "Date range",
        "Compare": "Compare",
        "Update report": "Update report",
        "Invalid date(s) selected.": "Invalid date(s) selected.",
        "yyyy-mm-dd": "yyyy-mm-dd", //date string format


        "Revenue": "Revenue",
        "Total revenue": "Total revenue",
        "Cost of sales": "Cost of sales",
        "Total cost of sales": "Total cost of sales",
        "Gross profit": "Gross profit",
        "Operating expenses": "Operating expenses",
        "Research and development": "Research and development",
        "Sales, general, and administration": "Sales, general, and administration",
        "Depreciation and amortization": "Depreciation and amortization",
        "Total operating expenses": "Total operating expenses",
        "Operating income": "Operating income",
        "Other income/expense": "Other income/expense",
        "Earnings before tax": "Earnings before tax",
        "Tax expense": "Tax expense",
        "Net income": "Net income",

        "Net income/expense from investing activities": "Net income/expense from investing activities",
        "Net income/expense from financing activities": "Net income/expense from financing activities",
        "Total other income/expense, net": "Total other income/expense, net",
        "Earnings before interest and tax": "Earnings before interest and tax",
        "Interest expense": "Interest expense",
        "Interest income": "Interest income",
        "Non-recurring and extraordinary items": "Non-recurring and extraordinary items",

        "Income and Expense Report": "Income and Expense Report",
        "Income": "Income",
        "Total Income": "Total income",
        "Expenses": "Expenses",
        "Total expenses": "Total expenses",
        "Total Income less Expenses": "Total income less expenses",
        "Detailed View": "Detailed View",

        "Other expense": "Other expense",
        "Expense from investing activities" : "Expense from investing activities",
        "Expense from financing activities": "Expense from financing activities",
        "Total other expense": "Total other expense",
        "Non-operating income and expenses": "Non-operating income and expenses",
        "Total non-operating income and expenses, net": "Total non-operating income and expenses, net",

    },
    "zh-TW": {
        "Options": "設定選項",
        "From:": "自:",
        "To:": "至:",
        "Date range": "日期區間",
        "Compare": "比較對照",
        "Update report": "更新報表",
        "Invalid date(s) selected.": "日期有誤。",
        "yyyy-mm-dd": "yyyy-mm-dd", //date string format

        "Revenue": "營業收入",
        "Total revenue": "營業收入合計",
        "Cost of sales": "營業成本",
        "Total cost of sales": "營業成本合計",
        "Gross profit": "營業毛利",
        "Operating expenses": "營業費用",
        "Research and development": "研究發展費用",
        "Sales, general, and administration": "銷售、總務及管理費用",
        "Depreciation and amortization": "折舊及攤提",
        "Total operating expenses": "營業費用合計",
        "Operating income": "營業利益",
        "Other income/expense": "業外 收入／支出",
        "Earnings before tax": "稅前盈餘",
        "Tax expense": "賦稅費用",
        "Net income": "稅後淨利",

        "Net income/expense from investing activities": "投資活動之淨收入／支出",
        "Net income/expense from financing activities": "籌資活動之淨收入／支出",
        "Total other income/expense, net": "業外 收入／支出 合計",
        "Earnings before interest and tax": "息稅前淨利",
        "Interest income": "利息收入",
        "Interest expense": "利息支出",
        "Non-recurring and extraordinary items": "非經常性項目",

        "Income and Expense Report": "損益表",
        "Income": "收益",
        "Total Income": "合計收益",
        "Expenses": "費用",
        "Total expenses": "合計費用",
        "Total Income less Expenses": "總收益 減 總費用",
        "Detailed View": "細項檢視",

        "Other expense": "其他費用",
        "Expense from investing activities" : "投資活動之費用",
        "Expense from financing activities": "籌資活動之費用",
        "Total other expense": "合計費用",
        "Non-operating income and expenses": "營業外收入和費用",
        "Total non-operating income and expenses, net": "合計營業外收入和費用淨額",

    }
}