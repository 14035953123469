export const vendorsText = {
    "en-US": {
        "Add a Vendor": "Add a Vendor",
        "Edit Vendor Details": "Edit Vendor Details",
        "Please provide a vendor name.": "Please provide a vendor name.",
        "Vendor Name": "Vendor Name",
        "Contact Name": "Contact Name",
        "Email": "Email",
        "Phone Number": "Phone Number",
        "Are you sure you want to delete this vendor?": "Are you sure you want to delete this vendor?",
        "Please remove all journal entries associated with this vendor and try again.": "Please remove all journal entries associated with this vendor and try again.",
        "Cannot delete this vendor.": "Cannot delete this vendor.",
        "Vendors": "Vendors",
        "A vendor with this name already exists in this ledger.": "A vendor with this name already exists in this ledger.",
    },
    "zh-TW": {
        "Add a Vendor": "建立新支出對象",
        "Edit Vendor Details": "修改供應商（支出對象）資訊",
        "Please provide a vendor name.": "請輸入供應商的名稱。",
        "Vendor Name": "供應商名稱",
        "Contact Name": "連絡人名字",
        "Email": "電郵址",
        "Phone Number": "電話",
        "Are you sure you want to delete this vendor?": "你確定要删除這個供應商（支出對象）嗎？",
        "Please remove all journal entries associated with this vendor and try again.": "請先删除這個供應商的所有相關分錄後，才能删除此供應商。",
        "Cannot delete this vendor.": "無法删除本供應商（支出對象）。",
        "Vendors": "支出對象（供應商）名册",
        "A vendor with this name already exists in this ledger.": "本帳册中已存在這個供應商（支出對象）名稱。",
    },
}