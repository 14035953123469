export const reportsText = {
    "en-US": {
        "Home": "Home",
        "Reports": "Reports",
        "Account Transactions Report": "Account Transactions Report",
        "Account:": "Account:",
        "Account": "Account",
        "Date": "Date",
        "Description": "Description",
        "Debit": "Debit",
        "Credit": "Credit",
        "Balance": "Balance",
        "Starting balance": "Starting balance",
        "Ending balance": "Ending balance",
        "Total change": "Total change",
        "No vendor": "No vendor",
        "Total Expenses": "Total expenses",
        "No customer": "No customer",
        "Total Income": "Total income",

    },
    "zh-TW": {
        "Home": "首頁",
        "Reports": "報表",
        "Account Transactions Report": "明細分類帳",
        "Account:": "科目:",
        "Account": "科目",
        "Date": "日期",
        "Description": "描述",
        "Debit": "借方",
        "Credit": "貸方",
        "Balance": "餘額",
        "Starting balance": "期初餘額",
        "Ending balance": "期末餘額",
        "Total change": "期間合計",
        "No vendor": "無記載支出對象",
        "Total Expenses": "支出合計",
        "No customer": "無記載收入來源",
        "Total Income": "收入合計",

    }
}